export default [
    ["getWardType", []],
    ["getRoomType", []],
    ["getAdmitStatusChoice", []],
    ["getAdmitOrder", []],
    ["getAdmitOrderDetail", []],
    ["estimateAdmit", []],
    ["createAdmitOrder", []],
    ["updateAdmitOrder", []],
    ["printReservation", []],
    ["getAdmitDay", []],
    ["getAdmitOrderRoomItemStatus", []],
    ["putAdmitOrderRoomItem", []],
    ["admService", []],
    ["getAdverseReaction", []],
    ["getAdverseReactionList", []],
    ["postAdverseReaction", []],
    ["putAdverseReaction", []],
    ["getAnesthesiaQueue", []],
    ["getAnesthesiaQueueZoneChoice", []],
    ["getAnesthesiaOrderWithId", []],
    ["getAnesthesiaConsultWithId", []],
    ["putAnesthesiaOrderAction", []],
    ["putAnesthesiaConsultAction", []],
    ["getInvestigationResult", []],
    ["getAnesthesiaData", []],
    ["getPreAnestheticAnesthesiaRecord", []],
    ["getPreAnestheticAnesthesiaLog", []],
    ["printFormAns", []],
    ["postPreAnestheticAnesthesiaRecord", []],
    ["putPreAnestheticAnesthesiaRecord", []],
    ["getAnesthesiaGraph", []],
    ["getAgentTemplate", []],
    ["getAgentByRecord", []],
    ["getAgentItem", []],
    ["putAgentItem", []],
    ["postAnesthesiaAgent", []],
    ["getAnesthesiaEventMedication", []],
    ["getEventMedicationByRecord", []],
    ["postAnesthesiaEventMedication", []],
    ["putAnesthesiaEventMedication", []],
    ["getMonitorTemplate", []],
    ["getMonitorByRecord", []],
    ["getMonitorItem", []],
    ["putMonitorItem", []],
    ["postAnesthesiaMonitor", []],
    ["getIntakeOutputTemplate", []],
    ["getIntakeByRecord", []],
    ["postAnesthesiaIntake", []],
    ["getIntakeItem", []],
    ["putIntakeItem", []],
    ["getOutputByRecord", []],
    ["postAnesthesiaOutput", []],
    ["getOutputItem", []],
    ["putOutputItem", []],
    ["getAnesthesiaBillWithId", []],
    ["getAnesthesiaTemplateList", []],
    ["getAnesthesiaTemplateWithId", []],
    ["getAnesthesiaBillLog", []],
    ["printAnesthesiaBill", []],
    ["postAnesthesiaBill", []],
    ["putAnesthesiaBill", []],
    ["getAppointmentWithId", []],
    ["deleteAppointmentWithId", []],
    ["cancelAppointment", ["appointmentId", "params"]],
    ["updateAppointmentWithId", []],
    ["postponeAppointmentWithId", []],
    ["getDoctorWorkAtDivision", []],
    ["convertDoctorScheduleToChoice", []],
    ["getAppointmentOrder", []],
    ["getAppointmentOrderRaw", []],
    ["getDoctorNote", []],
    ["getDoctorNoteGroup", []],
    ["getDoctorScheduleAppointment", []],
    ["postAppointmentRequest", []],
    ["printAppointment", []],
    ["_convertToAppointmentModel", []],
    ["getQueueList", []],
    ["getInvoice", []],
    ["getInvoiceDetail", []],
    ["postInvoice", []],
    ["getInvoiceItemByItem", []],
    ["postPaymentParameters", []],
    ["getReceipt", []],
    ["getBloodGroupWithPatient", []],
    ["getBloodTransfusion", []],
    ["getEncounterStatus", []],
    ["getEncounter", []],
    ["putEncounter", []],
    ["getNationalities", []],
    ["getDistrict", []],
    ["getCity", []],
    ["getListPreName", []],
    ["getProvince", []],
    ["getCountry", []],
    ["getReligion", []],
    ["getConstance", []],
    ["getChoices", []],
    ["getChoicesEpisodeType", []],
    ["createEpisode", []],
    ["getEpisode", []],
    ["getEpisodeFromEN", []],
    ["getEpisodeList", []],
    ["changeENToEpisode", []],
    ["cancelENFromEpisode", []],
    ["getENListFromEpisode", []],
    ["getDoctorList", []],
    ["getDoctorNameCodeList", []],
    ["getSpeciality", []],
    ["getProgressionCycleByEmr", []],
    ["getProgressionCycle", []],
    ["getClinicalTermSet", []],
    ["getClinicalTerm", []],
    ["getBarcodePrefix", []],
    ["getDefaultBarcode", []],
    ["getRejectedOrder", []],
    ["getCleaningChoices", []],
    ["getCareer", []],
    ["getICD10ListWithCode", []],
    ["getICD9CMListWithCode", []],
    ["getICD10ListWithTerm", []],
    ["getICD9CMListWithTerm", []],
    ["getICD10ListWithMedTerm", []],
    ["getICD9CMListWithMedTerm", []],
    ["getMiscellaneous", []],
    ["getICD10SublevelListWithCode", []],
    ["getICD9CMSublevelListWithCode", []],
    ["postMiscellaneousOrderPreview", []],
    ["getMiscellaneousOrder", []],
    ["deleteMiscellaneousOrder", []],
    ["patchMiscellaneousOrder", []],
    ["postMiscellaneousOrderCreate", []],
    ["getDivision", []],
    ["getDivisionDetail", []],
    ["getDivisionForOPD", []],
    ["getDivisionChoice", []],
    ["getDivisionForOPDChoice", []],
    ["getDoctorChoice", []],
    ["getCurrentDoctor", []],
    ["deleteProgressionCycle", []],
    ["postProgressionCycle", []],
    ["putProgressionCycle", []],
    ["getEncounterSearch", []],
    ["getDocumentType", []],
    ["getDocumentCategory", []],
    ["getDoctor", []],
    ["getScannedDocument", []],
    ["putScannedDocumentUpdateSecret", []],
    ["patchScannedDocument", []],
    ["postScannedDocument", []],
    ["getChoicesCheckoutCause", []],
    ["getZoneOfDivision", []],
    ["changeZone", []],
    ["getEncounterSearchDetail", []],
    ["getTaskTrackingResult", []],
    ["getChoicesBillTransactionTypeIPD", []],
    ["getChoicesAdmitOrderType", []],
    ["getEncounterPatient", []],
    ["getProduct", []],
    ["postMiscellaneousOrderListCreate", []],
    ["postPatientAddress", []],
    ["getPatientAddressList", []],
    ["getPatientAddressDetail", []],
    ["putPatientAddressDetail", []],
    ["deletePatientAddressDetail", []],
    ["getChoicesANSOrderStatus", []],
    ["getDoctorOrderDetail", []],
    ["getEncounterPatientOptimized", []],
    ["getPatientDiagnosisList", []],
    ["checkDoctorFeeOrderWithEMR", []],
    ["getAdmissionForm", []],
    ["putAdmissionForm", []],
    ["createProgressionNote", []],
    ["putProgressNote", []],
    ["putProgressNoteById", []],
    ["dpoService", []],
    ["getMedicalRecord", []],
    ["discharged", []],
    ["cancelCheckIn", []],
    ["getDiagnosis", []],
    ["updateDiagnosis", []],
    ["getPatientDiagnosis", []],
    ["getClinialType", []],
    ["getDiagnosisTemplate", []],
    ["getDoctorCertificateType", []],
    ["postDoctorCertificate", []],
    ["getDiagnosisSummary", []],
    ["getDoctorCertificatesPrintlog", []],
    ["getDoctorOrder", []],
    ["putDoctorOrder", []],
    ["postDoctorConsultOrder", []],
    ["getDoctorConsultOrderWithId", []],
    ["updateDoctorConsultOrderWithId", []],
    ["getMedicalRecordGallery", []],
    ["postMedicalRecordGallery", []],
    ["putMedicalRecordPicture", []],
    ["getTemplateGalleryImage", []],
    ["_convertToConsultOrderModel", []],
    ["estimateDoctorOrder", []],
    ["getMedicalRecordEmrDetail", []],
    ["getPatientHistoryEMR", []],
    ["getSensitiveNoteEMR", []],
    ["postSensitiveNoteEMR", []],
    ["putSensitiveNoteEMR", []],
    ["putPatientHistoryEMR", []],
    ["getPhysicalExamOrgan", []],
    ["postPatientScreenNew", []],
    ["putPatientScreenUpdate", []],
    ["patchPatientDiagnosis", []],
    ["getProviderInfo", []],
    ["getRoomList", []],
    ["getEventTypeChoices", []],
    ["getEncounterDoctorOrder", []],
    ["putMonitorEvent", []],
    ["getMonitorEvent", []],
    ["deleteMonitorEvent", []],
    ["getMedicationsRecordChoices", []],
    ["createMedicationsRecordChoices", []],
    ["getFlowSheetVitalSigns", []],
    ["getFlowSheetMedication", []],
    ["getFlowSheetVentilater", []],
    ["getFlowSheetArterial", []],
    ["getFlowSheetOverALLBalance", []],
    ["getGraphicSheetOverAllBalance", []],
    ["getIcuIntakeOutputDetail", []],
    ["getIntakeOutputChoise", []],
    ["getIntakeOutput", []],
    ["getFlowsheetDiet", []],
    ["putMedicationRecordItem", []],
    ["postMedicationRecordItem", []],
    ["postGraphicSheetPrint", []],
    ["postFlowSheetPrint", []],
    ["postIntakeOutputRecordItemCreateUpdate", []],
    ["getPeriodWorkerTimeSlot", []],
    ["icuService", []],
    ["getImagingExamResult", []],
    ["getSentClaimChoices", []],
    ["getChoicesAdmitSource", []],
    ["getChoicesPreDischargeType", []],
    ["getChoicesDischargeType", []],
    ["getChoicesDivision", []],
    ["getChoicesSentClaimStatus", []],
    ["createARTransaction", []],
    ["getARTransaction", []],
    ["getCoveragePayerSentClaimGroup", []],
    ["getBillTransactionIPD", []],
    ["getBillTransactionIPDWithId", []],
    ["updateBillTransactionIPDWithId", []],
    ["updateIPDDiagnosisSentClaimWithId", []],
    ["updateIPDProcedureSentClaimWithId", []],
    ["putBillTransactionUpdateStatus", []],
    ["putBillTransaction", []],
    ["getBillTransactionIPDSummary", []],
    ["getIPDDiagnosisSentClaim", []],
    ["getIPDProcedureSentClaim", []],
    ["getBillTransactionIPDItem", []],
    ["doGenerateSentClaimDataIPD", []],
    ["arTaskTrackingIPDGenerateSentClaim", []],
    ["arTaskTrackingIPDUpdateSentClaim", []],
    ["updateSentClaimDataIPDFromARId", []],
    ["generateSentClaimIPDFileFromARId", []],
    ["importResponseFileIPD", []],
    ["getSentClaimTransactionIPD", []],
    ["getEncounterFromARId", []],
    ["getCenterLabDivision", []],
    ["getCentalLabTestResultFitlerList", []],
    ["getCentalLabTestResultComparable", []],
    ["getInterfaceSummaryReportDetail", []],
    ["getCentralLabResultList", []],
    ["getSearchQueue", []],
    ["getANCRecordList", []],
    ["getPreviousPregnancy", []],
    ["getANCSummary", []],
    ["getANCItem", []],
    ["getANCLab", []],
    ["getFetalLie", []],
    ["postLabInvestigation", []],
    ["getLRLab", []],
    ["postSaveANCRecord", []],
    ["putLabInvestigation", []],
    ["getBirthCertificateApplication", []],
    ["postBirthCertificateApplication", []],
    ["putBirthCertificateApplication", []],
    ["putPrintBirthCertificateApplication", []],
    ["getObstetricBasicInfo", []],
    ["postObstetricBasicInfo", []],
    ["putObstetricBasicInfo", []],
    ["putPrintObstetric", []],
    ["getObstetricLabour", []],
    ["postObstetricLabour", []],
    ["putObstetricLabour", []],
    ["getObstetricInfant", []],
    ["postObstetricInfant", []],
    ["putObstetricInfant", []],
    ["getObstetricPostLabor", []],
    ["postObstetricPostLabour", []],
    ["putObstetricPostLabour", []],
    ["getEDC", []],
    ["postNewborn", []],
    ["getCheckOutChoice", []],
    ["putLRCheckOut", []],
    ["printANCRecordByEncounter", []],
    ["getPrintAPI", []],
    ["getRegisterByTokenFacebook", []],
    ["getLatestChatChannel", []],
    ["getChatChannelMessageList", []],
    ["getChatChannelMessageListMSG", []],
    ["getMessageFromURL", []],
    ["postChatChannelMessage", []],
    ["postChatChannelMessageMSG", []],
    ["postReadMessage", []],
    ["postUpdateSubscription", []],
    ["getOperatingOrder", []],
    ["postDeliverOperatingAppointment", []],
    ["getOrLocation", []],
    ["getOperativeImage", []],
    ["getOperatingProcedureSummary", []],
    ["printOperativeNote", []],
    ["newPRXService", []],
    ["getDiagRule", []],
    ["getDiagFormClassify", []],
    ["getDiagForm", []],
    ["getDiagFormDetail", []],
    ["getDivisionHasUser", []],
    ["getDivisionProfile", []],
    ["createDivisionHasUser", []],
    ["getDiagRuleDetail", []],
    ["postDiagRule", []],
    ["postTuhSetPatientHn", []],
    ["postListPatientFromDiagForm", []],
    ["postListPatientFromPatient", []],
    ["getProxyPatientHasDivisionNotFollow", []],
    ["getProxyPatientHasDivisionPatientChat", []],
    ["patchDiagRuleDetail", []],
    ["patchDiagRuleName", []],
    ["patchDiagRulePublish", []],
    ["patchDiagForm", []],
    ["postDiagFormMonitor", []],
    ["patchDiagRuleActive", []],
    ["getPublishedDiagRule", []],
    ["postPublishedDiagRule", []],
    ["patchPublishedDiagRule", []],
    ["getTriageLevelClassify", []],
    ["getClassifyUser", []],
    ["getMasterTriageLevelClassify", []],
    ["getEncounterTriage", []],
    ["geNurseNoteList", []],
    ["geProgressNoteList", []],
    ["getChannelDetail", []],
    ["geNurseNoteEncounter", []],
    ["getProxyPatient", []],
    ["getProxyPatientAllergy", []],
    ["getProxyPatientList", []],
    ["patchProxyPatientHasDivision", []],
    ["getProxyPatientHasDivisionAdmin", []],
    ["patchProxyPatientHasDivisionAdmin", []],
    ["patchEncounterReclassify", []],
    ["getChatChannel", []],
    ["getProxyPatientHasDivisionIdAdmin", []],
    ["getTuhUsersList", []],
    ["getProxyPatientHasDivision", []],
    ["getPatient", []],
    ["patchPatient", []],
    ["postEncounterPatientAppointment", []],
    ["getDoctorWorkSchedule", []],
    ["getAvailableDivisionServiceBlock", []],
    ["postSelectDivisionServiceBlock", []],
    ["postRequestChangeDivisionServiceBlock", []],
    ["getEncounterDetail", []],
    ["getEncounterTriageDetail", []],
    ["getPatientQueue", []],
    ["getAppointedPatientQueue", []],
    ["getShippingAddress", []],
    ["postShippingAddress", []],
    ["getInitPatientAddress", []],
    ["getDashboard", []],
    ["getOfficialAccountDashboard", []],
    ["getDashboardAppointment", []],
    ["getResolveChatChannel", []],
    ["postRequestOA", []],
    ["getListOA", []],
    ["postFollowOA", []],
    ["getOAInviteLink", []],
    ["postCreatejoinOARequest", []],
    ["getListJoinOARequest", []],
    ["postApproveJoinOARequest", []],
    ["postCreateUpdateSchedule", []],
    ["postPatientFromDiagRule", []],
    ["deleteJoinOARequest", []],
    ["deleteDivisionHasUserOA", []],
    ["getListDivisionHasUserOA", []],
    ["getListAvailabelTraigeLevel", []],
    ["getListUserRoleOA", []],
    ["getOADivisionProfile", []],
    ["getListOARequestCheck", []],
    ["patchDivisionHasUserOA", []],
    ["patchOADivisionProfile", []],
    ["getOAUserProfile", []],
    ["getListSchedule", []],
    ["getDivisionClassify", []],
    ["getChatChannelTriageLevel", []],
    ["getListChatChannelTriageLevelDiagRule", []],
    ["getListAvailabelTriageLevelDiagRule", []],
    ["getPatientFromDiagRule", []],
    ["putPatientFromDiagRule", []],
    ["patchOAUserProfile", []],
    ["getVaccineHospital", []],
    ["getAvailableVaccineDivision", []],
    ["postVaccineServiceBlock", []],
    ["postVaccineNote", []],
    ["putVaccineServiceBlock", []],
    ["deleteVaccineServiceBlock", []],
    ["getListVaccine", []],
    ["getListVaccineLot", []],
    ["getListVaccineNote", []],
    ["getVaccineNoteDetail", []],
    ["patchVaccineNote", []],
    ["postRequestBotMessage", []],
    ["getPatientData", []],
    ["getPatientWardData", []],
    ["periodWorkerOptions", []],
    ["getFlowSheet", []],
    ["getPatientAssessment", []],
    ["getFormDataLatest", []],
    ["getFormDataActionLog", []],
    ["getPatientWeightAndHeight", []],
    ["getVitalSignValue", []],
    ["getVitalSignValueRaw", []],
    ["getMeasurement", []],
    ["getVitalSignType", []],
    ["getVitalSign", []],
    ["getLastHeight", []],
    ["postMeasurement", []],
    ["patchMeasurement", []],
    ["postVitalSignUpdate", []],
    ["deleteVitalSign", []],
    ["getMultidisciplinary", []],
    ["getMultidisciplinaryLogs", []],
    ["saveMultidisciplinary", []],
    ["updateMultidisciplinary", []],
    ["printMultidisciplinary", []],
    ["getFormType", []],
    ["getFormDataNurseNote", []],
    ["deleteMultidisciplinary", []],
    ["getNurseDiagnosisResultGroup", []],
    ["putNurseDiagPrintPhv", []],
    ["getNurseNoteLatest", []],
    ["getNurseNoteItem", []],
    ["postNurseNoteItem", []],
    ["putNurseNoteItem", []],
    ["deleteNurseNoteItem", []],
    ["putNurseNote", []],
    ["getDrOrder", []],
    ["getVentilatorMode", []],
    ["putEncounterVitalSignType", []],
    ["putRemoveEncounterVitalSignType", []],
    ["postFormData", []],
    ["putFormData", []],
    ["putMeasurement", []],
    ["ptmService", []],
    ["createServiceSlot", []],
    ["updateServiceSlot", []],
    ["loadServiceSlot", []],
    ["loadDivisionServiceBlock", []],
    ["getDivisionServiceBlockDetail", []],
    ["listPatientAppointment", []],
    ["getPatientAppointment", []],
    ["updatePatientAppointment", []],
    ["getPatientAppointmentUpdate", []],
    ["getPatientInfo", []],
    ["getSearchAllPatient", []],
    ["getPatientOldName", []],
    ["getPatientNameWithID", []],
    ["getPatientByHN", []],
    ["getBloodType", []],
    ["regService", []],
    ["putArriveTRTOrder", []],
    ["getTECSession", []],
    ["getListMedAdminRecord", []],
    ["postCreateMedAdminRecord", []],
    ["putUpdateMedAdminRecord", []],
    ["putMedAdminRecord", []],
    ["putDrugOrderItemIdAdminDivision", []],
    ["postMedAdminRecord", []],
    ["getPrintMedRecord", []],
    ["getDrugOrderItemForMedReconcile", []],
    ["tpdService", []],
    ["getTreatmentOrder", []],
    ["getTreatmentOrderPerformer", []],
    ["getTreatment", []],
    ["postTreatmentOrderEstimate", []],
    ["postTreatmentOrder", []],
    ["putTreatmentOrderEncounterArrive", []],
    ["getUserPermission", []],
    ["getUserDetail", []],
    ["postUserLogin", []],
    ["getUserEmployee", []],
    ["getUserTokenize", []],
    ["getUserTokenizeLicense", []],
    ["getUsers", []],
    ["getUserProfile", []],
    ["updateUserProfile", []],
    ["getUserPosition", []],
    ["getUserWithPatient", []],
    ["postUserSetPassword", []],
    ["DrugSearch", []],
    ["getLocation", []],
    ["getDashboardAppointment", []],
    ["getOperatingOrderDetailByEmr", []],
    ["getDoctorDetail", []],
    ["getPatientDetailView", []],
    ["getDiagnosisMedicalRecordDetail", []],
    ["getTreatmentDetail", []],
    ["getHospitalNameForPrintList", []],
    ["getPatientAllergyForPrintList", []],
    ["getPreNameForPrintList", []],
    ["getAdmitOrderForPrintList", []],
    ["getCudentMedCertForPrintList", []],
    ["getBilEncounterSummaryForPrintList", []],
    ["getIpdMedicalBilForPrintList", []],
    ["getInvoiceItemList", []],

    // Ishealth-v3 port into CUDent (vise versa)
    ["getPatientList", []],
    ["getNatinalities", []],
    ["choiceNationalities", []],
    // ["getPatientOldName", []],
    // ["getPatientByHN", []],
    ["searchPatients", []],
    ["loadPatientOldName", []],
    ["getDoctorNoteList", []],
    ["getDoctorNoteGroupList", []],
    ["getV2EncounterTriage", []],
    // ANS
    ["getAnesthesiaTemplateList", []],
]